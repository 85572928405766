<template>
  <div class="csn-promotion">
    <div class="csn-promotion-list">
      <div
        v-for="(item, id) in OFFER_DICTIONARY"
        :key="id"
        class="csn-promotion-item"
      >
        <div class="csn-promotion-item-image">
          <img :src="item.headerImage" alt="offer-header-image" />
        </div>
        <div class="csn-promotion-item-info">
          <div class="csn-promotion-item-text-container">
            <span class="csn-promotion-item-header">{{ item.title }}</span>
            <span class="csn-promotion-item-description">
              {{ item.quickDescription }}
            </span>
          </div>
          <div class="csn-promotion-item-btn-container">
            <Route :to="{ name: promotionViewRoute, params: { slug: id } }">
              <button class="csn-promotion-item-btn casino-btn-default">
                <span class="csn-promotion-item-btn-label">
                  {{ t('more_info') }}
                </span>
                <SlideArrow />
              </button>
            </Route>
          </div>
        </div>
      </div>
    </div>
    <VerticalScrollPaginator
      :hasMore="hasMore"
      :isPending="IS_OFFER_PENDING"
      :loadMore="SET_OFFER_DICTIONARY"
    />
    <div class="csn-promotion-fairness">
      <div class="csn-promotion-fairness-header">
        {{ t('fairness_transparency') }}
      </div>
      <div class="csn-promotion-fairness-text">
        Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh,
        ut fermentum massa justo sit amet risus. Aenean lacinia bibendum nulla
        sed consectetur. Duis mollis, est non commodo luctus, nisi erat
        porttitor ligula, eget lacinia odio sem nec elit. Vivamus sagittis lacus
        vel augue laoreet rutrum faucibus dolor auctor. Nullam quis risus eget
        urna mollis ornare vel eu leo. Sed posuere consectetur est at lobortis.
        Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Sed
        posuere consectetur est at lobortis. Vestibulum id ligula porta felis
        euismod semper. Maecenas sed diam eget risus varius blandit sit amet non
        magna. Cum sociis natoque penatibus et magnis dis parturient montes,
        nascetur ridiculus mus.
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import {
  PROMOTIONS,
  RouteName,
  Module,
  HAS_MORE_PAGES,
  OFFER_DICTIONARY,
  IS_OFFER_PENDING,
  SET_OFFER_DICTIONARY,
} from '@/constants'
import { dest } from '@/helpers'
export default {
  name: PROMOTIONS,
  components: {
    Route: () => import('@/components/Route'),
    VerticalScrollPaginator: () =>
      import('@/components/VerticalScrollPaginator'),
    SlideArrow: () => import('@/components/svg/SlideArrow'),
  },
  computed: {
    ...mapState(Module.OFFER, [OFFER_DICTIONARY, IS_OFFER_PENDING]),
    ...mapGetters({ hasMore: dest([Module.OFFER, HAS_MORE_PAGES]) }),
    t() {
      return this.$createComponentTranslator(PROMOTIONS)
    },
    promotionViewRoute: () => RouteName.OFFERS_VIEW,
  },
  methods: {
    ...mapActions(Module.OFFER, [SET_OFFER_DICTIONARY]),
  },
}
</script>
